import { ITKBChiTietSaveExcelRequest } from "../models/request/thoi-khoa-bieu/ITKBChiTietSaveExcelRequest";
import { ITKBChiTietSelectByDateRequest, ThoiKhoaBieuPreviewByGiaoVienRequest } from "../models/request/thoi-khoa-bieu/ITKBChiTietSelectByDateRequest";
import { ITKBChiTietSelectRequest } from "../models/request/thoi-khoa-bieu/ITKBChiTietSelectRequest";
import { IThoiKhoaBieuChangeTeacherCheckTrungLichRequest } from "../models/request/thoi-khoa-bieu/IThoiKhoaBieuChangeTeacherCheckTrungLichRequest";
import { IThoiKhoaBieuChangeTeacherRequest } from "../models/request/thoi-khoa-bieu/IThoiKhoaBieuChangeTeacherRequest";
import { IThoiKhoaBieuDeleteMultipleRequest } from "../models/request/thoi-khoa-bieu/IThoiKhoaBieuDeleteMultipleRequest";
import { IThoiKhoaBieuDuplicateRequest } from "../models/request/thoi-khoa-bieu/IThoiKhoaBieuDuplicateRequest";
import { pl_tkb_chitiet } from "../models/response/thoi-khoa-bieu/pl_tkb_chitiet";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_TKB_CHITIET_PATH = "thoi-khoa-bieu-chi-tiet"

class TKBChiTietApi extends BaseCRUDApi<pl_tkb_chitiet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectByTkb(request: ITKBChiTietSelectRequest) {
        return apiClient.post(`${this._path}/select-by-tkb`, request)
    }
    SelectByGiaoVien(request: ITKBChiTietSelectByDateRequest) {
        return apiClient.post(`${this._path}/select-by-giaovien`, request)
    }
    PreviewByGiaoVien(request: ThoiKhoaBieuPreviewByGiaoVienRequest) {
        return apiClient.post(`${this._path}/preview-by-giaovien`, request)
    }
    LoadFromExcel(request: FormData) {
        return apiClient.upload(`${this._path}/load-from-excel`, request)
    }
    SaveDataExcel(request: ITKBChiTietSaveExcelRequest) {
        return apiClient.post(`${this._path}/save-data-excel`, request)
    }
    Duplicate(request: IThoiKhoaBieuDuplicateRequest) {
        return apiClient.post(`${this._path}/duplicate`, request)
    }
    DeleteMultipleAsync(request: IThoiKhoaBieuDeleteMultipleRequest) {
        return apiClient.post(`${this._path}/deletes-multiple`, request)
    }
    SelectByKhoiAsync(nam_hoc: string, dm_khoi_id: number, tuan: number) {
        return apiClient.get(`tkb-hoc-sinh/nam-hoc/${nam_hoc}/tuan/${tuan}/khoi/${dm_khoi_id}`)
    }
    ChangeTeacherAsync(request: IThoiKhoaBieuChangeTeacherRequest) {
        return apiClient.post(`${this._path}/change-teacher`, request)
    }
    ChangeTeachersAsync(request: IThoiKhoaBieuChangeTeacherRequest[]) {
        return apiClient.post(`${this._path}/change-teachers`, request)
    }
    ValidateTrungLichAsync(request: IThoiKhoaBieuChangeTeacherCheckTrungLichRequest) {
        return apiClient.post(`${this._path}/validate-trunglich`, request)
    }
    SelectByNgayKhoiHeAsync(dm_truong_id: number, nam_hoc: string, dm_khoi_id: number, dm_he_id: number, ngay: string) {
        return apiClient.get(`tkb-hoc-sinh/nam-hoc/${nam_hoc}/ngay/${ngay}/truong/${dm_truong_id}/khoi/${dm_khoi_id}/he/${dm_he_id}`)
    }
}
export const thoiKhoaBieuChiTietApi = new TKBChiTietApi({ path: API_TKB_CHITIET_PATH });

