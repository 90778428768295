import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ITKBPreviewByLopRequest } from "../../../models/request/thoi-khoa-bieu/ITKBPreviewByLopRequest";
import { ITKBHistoryUploadRequest } from "../../../models/request/thoi-khoa-bieu/ITKBHistoryUploadRequest";
import { pl_tkb } from "../../../models/response/thoi-khoa-bieu/pl_tkb";
import { pl_tkb_history_upload } from "../../../models/response/thoi-khoa-bieu/pl_tkb_history_upload";
import { IThoiKhoaBieuPreviewItem } from "../../../models/response/thoi-khoa-bieu/ThoiKhoaBieuPreviewItem";
import { eThoiKhoaBieuActionTypeIds, IThoiKhoaBieuChangeSelectedIds, IThoiKhoaBieuCloseConfirm,
     IThoiKhoaBieuDeleteErr, IThoiKhoaBieuDeleteStart, IThoiKhoaBieuDeleteSuccess, 
    IThoiKhoaBieuLoadErr, IThoiKhoaBieuLoadPreviewByLopErr, IThoiKhoaBieuLoadPreviewByLopStart, 
    IThoiKhoaBieuLoadPreviewByLopSuccess, IThoiKhoaBieuLoadStart, IThoiKhoaBieuLoadSuccess, 
    IThoiKhoaBieuSaveErr, IThoiKhoaBieuSaveStart, IThoiKhoaBieuSaveSuccess, 
    IThoiKhoaBieuShowConfirm, IThoiKhoaBieuShowDetail,  IThoiKhoaBieuCloseDetail,
    IThoiKhoaBieuLoadHistoryUploadStart, IThoiKhoaBieuLoadHistoryUploadSuccess, IThoiKhoaBieuLoadHistoryUploadErr
} from "../../action-types/thoi-khoa-bieu/IThoiKhoaBieuActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const thoiKhoaBieuActions = {
    loadStart: (): IThoiKhoaBieuLoadStart => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_tkb[]): IThoiKhoaBieuLoadSuccess => BaseAction(respone, eThoiKhoaBieuActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IThoiKhoaBieuLoadErr => BaseAction(messege, eThoiKhoaBieuActionTypeIds.LOAD_ERROR),
    loadPreviewByLopStart: (request: ITKBPreviewByLopRequest): IThoiKhoaBieuLoadPreviewByLopStart => BaseAction(request, eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_START),
    loadPreviewByLopSuccess: (respone: IThoiKhoaBieuPreviewItem[]): IThoiKhoaBieuLoadPreviewByLopSuccess => BaseAction(respone, eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_SUCCESS),
    loadPreviewByLopError: (messege: string): IThoiKhoaBieuLoadPreviewByLopErr => BaseAction(messege, eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_ERROR),
    showDetail: (data?: pl_tkb): IThoiKhoaBieuShowDetail => BaseAction(data, eThoiKhoaBieuActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IThoiKhoaBieuCloseDetail => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IThoiKhoaBieuShowConfirm => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IThoiKhoaBieuCloseConfirm => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IThoiKhoaBieuChangeSelectedIds => BaseAction(ids, eThoiKhoaBieuActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IThoiKhoaBieuDeleteStart => BaseAction(ids, eThoiKhoaBieuActionTypeIds.DELETE_START),
    deleteSuccess: (): IThoiKhoaBieuDeleteSuccess => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IThoiKhoaBieuDeleteErr => BaseAction(message, eThoiKhoaBieuActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_tkb): IThoiKhoaBieuSaveStart => BaseAction(data, eThoiKhoaBieuActionTypeIds.SAVE_START),
    saveSuccess: ():IThoiKhoaBieuSaveSuccess => BaseAction(undefined, eThoiKhoaBieuActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IThoiKhoaBieuSaveErr =>  BaseAction(message, eThoiKhoaBieuActionTypeIds.SAVE_ERR),

    loadHistoryUploadStart: (request: ITKBHistoryUploadRequest): IThoiKhoaBieuLoadHistoryUploadStart => BaseAction(request, eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_START),
    loadHistoryUploadSuccess: (respone: pl_tkb_history_upload[]): IThoiKhoaBieuLoadHistoryUploadSuccess => BaseAction(respone, eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_SUCCESS),
    loadHistoryUploadError: (messege: string): IThoiKhoaBieuLoadHistoryUploadErr => BaseAction(messege, eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_ERROR),
}