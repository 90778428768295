import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { ITKBPreviewByLopRequest } from "../../../models/request/thoi-khoa-bieu/ITKBPreviewByLopRequest"
import { ITKBHistoryUploadRequest } from "../../../models/request/thoi-khoa-bieu/ITKBHistoryUploadRequest"
import { pl_tkb } from "../../../models/response/thoi-khoa-bieu/pl_tkb"
import { pl_tkb_history_upload } from "../../../models/response/thoi-khoa-bieu/pl_tkb_history_upload"
import { IThoiKhoaBieuPreviewItem } from "../../../models/response/thoi-khoa-bieu/ThoiKhoaBieuPreviewItem"

export enum eThoiKhoaBieuActionTypeIds {
    LOAD_START = "TKB_LOAD_START",
    LOAD_SUCCESS = "TKB_LOAD_SUCCESS",
    LOAD_ERROR = "TKB_LOAD_ERROR",

    LOAD_HISTORY_UPLOAD_START = "TKB_LOAD_HISTORY_UPLOAD_START",
    LOAD_HISTORY_UPLOAD_SUCCESS = "TKB_LOAD_HISTORY_UPLOAD_SUCCESS",
    LOAD_HISTORY_UPLOAD_ERROR = "TKB_LOAD_HISTORY_UPLOAD_ERROR",

    LOAD_PREVIEW_BYLOP_START = "TKB_LOAD_PREVIEW_BYLOP_START",
    LOAD_PREVIEW_BYLOP_SUCCESS = "TKB_LOAD_PREVIEW_BYLOP_SUCCESS",
    LOAD_PREVIEW_BYLOP_ERROR = "TKB_LOAD_PREVIEW_BYLOP_ERROR",

    SHOW_DETAIL = "TKB_SHOW_DETAIL",
    CLOSE_DETAIL = "TKB_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "TKB_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "TKB_SHOW_CONFIRM",
    CLOSE_CONFIRM = "TKB_CLOSE_CONFIRM",

    DELETE_START = "TKB_DELETE_START",
    DELETE_SUCCESS = "TKB_DELETE_SUCCESS",
    DELETE_ERROR = "TKB_DELETE_ERROR",

    SAVE_START = "TKB_SAVE_START",
    SAVE_SUCCESS = "TKB_SAVE_SUCCESS",
    SAVE_ERR = "TKB_SAVE_ERR"
}

export interface IThoiKhoaBieuLoadStart {
    type: eThoiKhoaBieuActionTypeIds.LOAD_START,
}
export interface IThoiKhoaBieuLoadSuccess {
    type: eThoiKhoaBieuActionTypeIds.LOAD_SUCCESS,
    payload: pl_tkb[]
}
export interface IThoiKhoaBieuLoadErr {
    type: eThoiKhoaBieuActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IThoiKhoaBieuLoadHistoryUploadStart {
    type: eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_START,
    payload: ITKBHistoryUploadRequest
}
export interface IThoiKhoaBieuLoadHistoryUploadSuccess {
    type: eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_SUCCESS,
    payload: pl_tkb_history_upload[]
}
export interface IThoiKhoaBieuLoadHistoryUploadErr {
    type: eThoiKhoaBieuActionTypeIds.LOAD_HISTORY_UPLOAD_ERROR,
    payload: string
}

export interface IThoiKhoaBieuLoadPreviewByLopStart {
    type: eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_START,
    payload: ITKBPreviewByLopRequest
}
export interface IThoiKhoaBieuLoadPreviewByLopSuccess {
    type: eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_SUCCESS,
    payload: IThoiKhoaBieuPreviewItem[]
}
export interface IThoiKhoaBieuLoadPreviewByLopErr {
    type: eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_ERROR,
    payload: string
}

export interface IThoiKhoaBieuShowDetail {
    type: eThoiKhoaBieuActionTypeIds.SHOW_DETAIL,
    payload?: pl_tkb
}
export interface IThoiKhoaBieuCloseDetail {
    type: eThoiKhoaBieuActionTypeIds.CLOSE_DETAIL,
}
export interface IThoiKhoaBieuChangeSelectedIds {
    type: eThoiKhoaBieuActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IThoiKhoaBieuShowConfirm {
    type: eThoiKhoaBieuActionTypeIds.SHOW_CONFIRM
}
export interface IThoiKhoaBieuCloseConfirm {
    type: eThoiKhoaBieuActionTypeIds.CLOSE_CONFIRM
}

export interface IThoiKhoaBieuDeleteStart {
    type: eThoiKhoaBieuActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IThoiKhoaBieuDeleteSuccess {
    type: eThoiKhoaBieuActionTypeIds.DELETE_SUCCESS,
}
export interface IThoiKhoaBieuDeleteErr {
    type: eThoiKhoaBieuActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IThoiKhoaBieuSaveStart {
    type: eThoiKhoaBieuActionTypeIds.SAVE_START,
    payload: pl_tkb
}
export interface IThoiKhoaBieuSaveSuccess {
    type: eThoiKhoaBieuActionTypeIds.SAVE_SUCCESS
}
export interface IThoiKhoaBieuSaveErr {
    type: eThoiKhoaBieuActionTypeIds.SAVE_ERR,
    payload: string
}
export type IThoiKhoaBieuActionTypes = IThoiKhoaBieuLoadStart | IThoiKhoaBieuLoadSuccess | IThoiKhoaBieuLoadErr |
    IThoiKhoaBieuLoadPreviewByLopStart | IThoiKhoaBieuLoadPreviewByLopSuccess | IThoiKhoaBieuLoadPreviewByLopErr |
    IThoiKhoaBieuShowDetail | IThoiKhoaBieuCloseDetail |
    IThoiKhoaBieuChangeSelectedIds |
    IThoiKhoaBieuShowConfirm | IThoiKhoaBieuCloseConfirm |
    IThoiKhoaBieuDeleteStart | IThoiKhoaBieuDeleteSuccess | IThoiKhoaBieuDeleteErr |
    IThoiKhoaBieuSaveStart | IThoiKhoaBieuSaveSuccess | IThoiKhoaBieuSaveErr |
    IThoiKhoaBieuLoadHistoryUploadStart | IThoiKhoaBieuLoadHistoryUploadSuccess | IThoiKhoaBieuLoadHistoryUploadErr