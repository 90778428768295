import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimationPopup } from '../../../components/modal';
import { PopUpForm, PopUpFormActions } from '../../../components/pop-up-form/PopUpForm';
import { Button } from '../../../components/buttons';
import { diemDanhApi } from '../../../api/diemDanhApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/reducers';
import { PlaceHolder } from '../../../components/place-holder';
import { DefaultDataGrid } from '../../../components/data-grid';
import { Column } from 'devextreme-react/data-grid';
import { useCommonContext } from '../../../contexts/common';
import styles from "./DiemDanhDetailModal.module.css"
import DateBox from 'devextreme-react/date-box';
import { FormGroupInline } from '../../../components/form-group';
interface IDiemDanhDetailModalProps {
    animationOf: string,
    onClose: () => void,
    date: Date
}
const DiemDanhDetailModal = (props: IDiemDanhDetailModalProps) => {
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { translate } = useCommonContext();
    const { dm_truong_selected_id, nam_hoc } = useSelector((x: RootState) => x.common)
    const [filter, setFilter] = useState({
        fromDate: props.date,
        toDate: props.date
    });

    const refGrid = useRef<any>();

    useEffect(() => {
        handleGetDataSourceAsync()
    }, [props.date, dm_truong_selected_id])
    const handleGetDataSourceAsync = async () => {
        setIsLoading(true)
        const res = await diemDanhApi.SelectByTruongAsync({
            fromDate: filter.fromDate,
            toDate: filter.toDate,
            dm_truong_id: dm_truong_selected_id,
            nam_hoc
        })
        setIsLoading(false)
        if (res.is_success) {
            setDataSource(res.data)
        }
    }
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf])
    return (
        <>
            <AnimationPopup
                animationOf={props.animationOf}
                maxWidth={window.innerWidth - 150}
                minHeight={window.innerHeight - 100}

            >
                <PopUpForm onSubmit={() => { }}>
                    {isLoading && <PlaceHolder line_number={10} />}
                    {!isLoading &&
                        <div>
                            <div className={styles.filter}>
                                <div className={styles.left}>
                                    <div className={styles.item}>
                                        <FormGroupInline label='From date'>
                                            <DateBox value={filter.fromDate}
                                                onValueChanged={(e) => {
                                                    if (e.event) {
                                                        setFilter({ ...filter, fromDate: e.value })
                                                    }
                                                }}
                                            />
                                        </FormGroupInline>
                                    </div>
                                    <div className={styles.item}>
                                        <FormGroupInline label='To date'>
                                            <DateBox value={filter.toDate}
                                                onValueChanged={(e) => {
                                                    if (e.event) {
                                                        setFilter({ ...filter, toDate: e.value })
                                                    }
                                                }}
                                            />
                                        </FormGroupInline>
                                    </div>

                                </div>
                                <div className={styles.right}>
                                    <Button text='Export' type='success'
                                        icon='fas fa-file-export'
                                        style='text' onClick={() => {
                                            if (refGrid && refGrid.current && refGrid.current.instance) {
                                                refGrid.current.instance.exportToExcel();
                                            }
                                        }} />
                                    <Button text='Refresh' type='success'
                                        icon='fas fa-sync-alt'
                                        style='filled' onClick={() => {
                                            handleGetDataSourceAsync()
                                        }} />
                                </div>

                            </div>
                            <DefaultDataGrid dataSource={dataSource}
                                keyExpr='id'
                                gridRef={refGrid}
                                height={window.innerHeight - 200}
                                width={window.innerWidth - 180}
                                defaultPageSize={50}
                            >
                                <Column dataField={"ngay_diem_danh"} caption={translate("Base.Label.Date")}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                />
                                <Column dataField={"ma_hs"} caption={translate("Base.Label.StudentID")} />
                                <Column dataField={"ho_ten"} caption={translate("Base.Label.StudentName")} />
                                <Column dataField={"emailPH"} caption={translate("Base.Label.ParentEmail")} />
                                <Column dataField={"sdtPH"} caption={translate("Base.Label.ParentPhone")} />
                                <Column dataField={"ten_lop_hc"} caption={translate("Base.Label.ClassName")} />
                                <Column dataField={"ten_lop_bo_mon"} caption={translate("Base.Label.SubjectClassName")} />
                                <Column dataField={"sis_diemdanh_type_name"} caption={"Base.Label.TypeAttendance"} />
                                <Column dataField={"ten_tiet"} caption={translate("Base.Label.Unit")} />
                                <Column dataField={"sis_diemdanh_status_name"} caption={translate("Base.Label.Status")} />
                                <Column dataField={"gio_diem_danh"} caption={translate("Base.Label.AttendanceTime")} />
                                <Column dataField={"ghi_chu"} caption={translate("Base.Label.Note")} />
                            </DefaultDataGrid>
                        </div>
                    }
                    <PopUpFormActions>
                        <Button type="normal" text="Base.Label.Close" onClick={onClose}></Button>{' '}

                    </PopUpFormActions>
                </PopUpForm>

            </AnimationPopup>
        </>
    );
};

export default DiemDanhDetailModal;