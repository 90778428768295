import { ISdtAccountPhuHuynh, IEmailAccountGiaoVien, ISdtAccountPhuHuynhDetail, IEmailAccountGiaoVienDetail } from "../../models/response/account/process-account";
import { eProcessAccountActionTypeIds, 
    IProcessAccountPhuHuynhStart, IProcessAccountPhuHuynhError, IProcessAccountPhuHuynhSuccess, 
    IProcessAccountPhuHuynhDetailStart, IProcessAccountPhuHuynhDetailError, IProcessAccountPhuHuynhDetailSuccess, 
    IProcessAccountGiaoVienStart, IProcessAccountGiaoVienError, IProcessAccountGiaoVienSuccess, 
    IProcessAccountGiaoVienDetailStart, IProcessAccountGiaoVienDetailError, IProcessAccountGiaoVienDetailSuccess,
    IProcessAccountGiaoVienShowDetail, IProcessAccountGiaoVienCloseDetail, IProcessAccountPhuHuynhShowDetail, IProcessAccountPhuHuynhCloseDetail
} from "../action-types/IProcessAccountActionTypes";
import { BaseAction } from "./IBaseActionResult";
export const processAccountActions = {
    getListSDTPhuhuynhStart: (keyword: string): IProcessAccountPhuHuynhStart => BaseAction(keyword, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_START),
    getListSDTPhuhuynhSuccess: (respone: ISdtAccountPhuHuynh[]): IProcessAccountPhuHuynhSuccess => BaseAction(respone, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SUCCESS),
    getListSDTPhuhuynhError: (messege: string): IProcessAccountPhuHuynhError => BaseAction(messege, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_ERROR),

    getListSDTPhuhuynhDetailStart: (keyword: string): IProcessAccountPhuHuynhDetailStart => BaseAction(keyword, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START),
    getListSDTPhuhuynhDetailSuccess: (respone: ISdtAccountPhuHuynhDetail[]): IProcessAccountPhuHuynhDetailSuccess => BaseAction(respone, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_SUCCESS),
    getListSDTPhuhuynhDetailError: (messege: string): IProcessAccountPhuHuynhDetailError => BaseAction(messege, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_ERROR),

    getListEmailGiaoVienStart: (email: string): IProcessAccountGiaoVienStart => BaseAction(email, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_START),
    getListEmailGiaoVienSuccess: (respone: IEmailAccountGiaoVien[]): IProcessAccountGiaoVienSuccess => BaseAction(respone, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SUCCESS),
    getListEmailGiaoVienError: (messege: string): IProcessAccountGiaoVienError => BaseAction(messege, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_ERROR),

    getListEmailGiaoVienDetailStart: (email: string): IProcessAccountGiaoVienDetailStart => BaseAction(email, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START),
    getListEmailGiaoVienDetailSuccess: (respone: IEmailAccountGiaoVienDetail[]): IProcessAccountGiaoVienDetailSuccess => BaseAction(respone, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_SUCCESS),
    getListEmailGiaoVienDetailError: (messege: string): IProcessAccountGiaoVienDetailError => BaseAction(messege, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_ERROR),
    
    showProcessAccountGiaoVienDetail: (data?: IEmailAccountGiaoVien): IProcessAccountGiaoVienShowDetail => BaseAction(data, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_SHOW_DETAIL),
    closeProcessAccountGiaoVienDetail: (): IProcessAccountGiaoVienCloseDetail => BaseAction(undefined, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_CLOSE_DETAIL),
    
    showProcessAccountPhuHuynhDetail: (data?: ISdtAccountPhuHuynh): IProcessAccountPhuHuynhShowDetail => BaseAction(data, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_SHOW_DETAIL),
    closeProcessAccountPhuHuynhDetail: (): IProcessAccountPhuHuynhCloseDetail => BaseAction(undefined, eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_CLOSE_DETAIL),
    
}

