export interface IUserInfoModel {
    id: number,
    username: string,
    img?: string,
    full_name: string,
    menus: IMenuModel[],
    apis: IApiModel[],
    campus: ICampusModel[],
    sub_systems: IUserSubSystemModel[],
    roles: IRoleModel[]

}
export interface IUserFullInfoModel {
    id: number;
    username: string;
    full_name: string;
    phone_number: string;
    email: string;
    is_active: boolean;
    img: string;
    sis_giaovien_id: number;
    role_ids:string;
}
export interface IMenuModel {
    id: number;
    name: string;
    name_en: string;
    path: string;
    icon: string;
    menu_parent_id: number;
    is_active: boolean;
    items: IMenuModel[];
    alert_number: number;
    expanded: boolean;
    menu_id: number;
}
export interface IApiModel {
    id: number;
    url: string;
    method: string
}
export interface ICampusModel {
    id: number;
    ma_truong: string;
    ten_truong: string;
    ten_truong_en: string;
    viet_tat: string;
    dm_coso_id: number;
    dm_coso_viettat: string;
    is_require_duyet_baiviet: boolean;
    show_info_private_parent: boolean;
}
export interface IRoleModel {
    id: number;
    name: string;
}
export interface IUserSubSystemModel {
    id: number;
    name: string;
    name_en: string;
    icon: string;
    url: string;
    is_accessed: boolean;
}
export const initialUserInfo: IUserInfoModel = {
    apis: [],
    campus: [],
    full_name: "",
    id: 0,
    menus: [],
    roles: [],
    sub_systems: [],
    username: ""
}