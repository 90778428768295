import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { eProcessAccountActionTypeIds, IProcessAccountGiaoVienDetailStart, IProcessAccountGiaoVienStart, IProcessAccountPhuHuynhDetailStart, IProcessAccountPhuHuynhStart } from "../action-types/IProcessAccountActionTypes";
import { actions } from './../actions/actionsWrapper';
import { IBaseResponeModel } from "../../models/response/base-response";
const processAccountApi = apiWrapper.processAccount;

export function* processAccountSaga(): any {
    yield takeLatest(eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_START, loadSdtPhuHuynh)
    yield takeLatest(eProcessAccountActionTypeIds.PROCESS_ACCOUNT_PHU_HUYNH_DETAIL_START, loadSdtPhuHuynhDetail)
    yield takeLatest(eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_START, loadSdtGiaoVien)
    yield takeLatest(eProcessAccountActionTypeIds.PROCESS_ACCOUNT_GIAO_VIEN_DETAIL_START, loadSdtGiaoVienDetail)
    // yield takeEvery(eProcessAccountActionTypeIds.DELETE_START, deleteWorker)
    // yield takeEvery(eProcessAccountActionTypeIds.SAVE_START, saveWorker)
    // yield takeEvery(eProcessAccountActionTypeIds.UPLOAD_EXCEL_START, loadFromExcelWorker)
    // yield takeEvery(eProcessAccountActionTypeIds.SAVE_EXCEL_START, saveFromExcelWorker)
}
function* loadSdtPhuHuynh(action: IProcessAccountPhuHuynhStart): any {
    console.log('bingo');
    const res: IBaseResponeModel = yield call([processAccountApi, processAccountApi.getListSDTPhuhuynh], action.payload)
    if (res.is_success) {
        yield put(actions.processAccount.getListSDTPhuhuynhSuccess(res.data))
    } else {
        yield put(actions.processAccount.getListSDTPhuhuynhError(res.message || "Không tải được giáo viên"))
    }
}

function* loadSdtPhuHuynhDetail(action: IProcessAccountPhuHuynhDetailStart): any {

    const res: IBaseResponeModel = yield call([processAccountApi, processAccountApi.getDetailSDTPhuhuynh], action.payload)
    if (res.is_success) {
        yield put(actions.processAccount.getListSDTPhuhuynhDetailSuccess(res.data))
    } else {
        yield put(actions.processAccount.getListSDTPhuhuynhDetailError(res.message || "Không tải được giáo viên"))
    }
}

function* loadSdtGiaoVien(action: IProcessAccountGiaoVienStart): any {

    const res: IBaseResponeModel = yield call([processAccountApi, processAccountApi.getListSDTGiaoVien], action.payload)
    if (res.is_success) {
        yield put(actions.processAccount.getListEmailGiaoVienSuccess(res.data))
    } else {
        yield put(actions.processAccount.getListEmailGiaoVienError(res.message || "Không tải được giáo viên"))
    }
}

function* loadSdtGiaoVienDetail(action: IProcessAccountGiaoVienDetailStart): any {

    const res: IBaseResponeModel = yield call([processAccountApi, processAccountApi.getDetailSDTGiaoVien], action.payload)
    if (res.is_success) {
        yield put(actions.processAccount.getListEmailGiaoVienDetailSuccess(res.data))
    } else {
        yield put(actions.processAccount.getListEmailGiaoVienDetailError(res.message || "Không tải được giáo viên"))
    }
}
