import { khenThuongKyLuatActions } from './khen-thuong-ky-luat/khenThuongKyLuatActions';
import { lopMonHocActions } from './lop-mon-hoc/lopMonHocActions';
import { xepHangHocSinhActions } from './xep-hang-hoc-sinh/xepHangHocSinhActions';
import { baoCaoTienBoActions } from './bao-cao-tien-bo/baoCaoTienBoActions';
import { accountActions } from './accountActions';
import { processAccountActions } from './processAccountActions';
import { appPhuHuynhActionsWrapper } from './app-phuhuynh/appPhuHuynhActionsWrapper';
import { authActions } from './authActions';
import { baiTapKetQuaLopActions } from './bai-tap/baiTapKetQuaLopActions';
import { baiTapTypeActions } from './bai-tap/baiTapTypeActions';
import { baiVietActions } from './bai-viet/baiVietActions';
import { baiVietDetailActions } from './bai-viet/baiVietDetailActions';
import { baiVietTrangThaiActions } from './bai-viet/baiVietTrangThaiActions';
import { categorySourceActions } from './categorySourceActions';
import { commonActions } from './commonActions';
import { dgdkHocTapActions } from './danh-gia/dinh-ky/dgdkHocTapActions';
import { dgdkNlpcActions } from './danh-gia/dinh-ky/dgdkNlpcActions';
import { dgtxKetQuaActions } from './danh-gia/thuong-xuyen/dgtxKetQuaActions';
import { dgtxKetQuaMonHocActions } from './danh-gia/thuong-xuyen/dgtxKetQuaMonHocActions';
import { danhMucActionsWrapper } from './danh-muc/danhMucActionsWrapper';
import { dictionaryActions } from './dictionaryAction';
import { giaoVienActions } from './danh-muc/giaoVienAction';
import { giaoVienNhanXetActions } from './giaoVienNhanXetActions';
import { hocSinhActionsWrapper } from './hoc-sinh/hocSinhActionsWrapper';
import { lopBoMonActionWrapper } from './lop-bo-mon/lopBoMonActionWrapper';
import { monHocActions } from './danh-muc/monHocAction';
import { lopHanhChinhSoDiemActions } from './lop-hanh-chinh/lopHanhChinhSoDiemActions';
import { nhapDiemActions } from './nhap-diem/nhapDiemActions';
import { notifyActions } from './notify/notifyAction';
import { loaiPhongActions } from './phong-hoc/loaiPhongActions';
import { phongHocActions } from './phong-hoc/phongHocActions';
import { soNhanXetItemActions } from './so-diem-tieu-hoc/dgdkDmPhamChatActions';
import { dgtxDmHocTapActions } from './so-diem-tieu-hoc/dgtxDmHocTapActions';
import { dgtxDmPhamChatActions } from './so-diem-tieu-hoc/dgtxDmPhamChatActions';
import { dgdkDmNlpcSetActions } from './so-diem-tieu-hoc/dinh-ky/dgdkDmNlpcSetActions';
import { soDiemActionsWrapper } from './so-diem/soDiemActionsWrapper';
import { thoiKhoaBieuActionsWrapper } from './thoi_khoa-bieu/thoiKhoaBieuActionsWrapper';
import { toKhoiActions } from './danh-muc/toKhoiAction';
import { uploadActions } from './upload-file/uploadActions';
import { diemDanhActions } from './diem-danh/diemDanhActions';
import { diemDanhAnActions } from './diem-danh-an/diemDanhAnActions';
import { diemDanhStatusActions } from './diem-danh/diemDanhStatusActions';
import { nhanXetHangNgayActions } from './nhan-xet/nhanXetHangNgayActions';
import { baiTapActions } from './bai-tap/baiTapAction';
import { baiTapKetQuaActions } from './bai-tap/baiTapKetQuaAction';
import { lopChuNhiemActions } from './lop-chu-nhiem/lopChuNhiemActions';
import { thaiDoHocTapActions } from './nhan-xet/thaiDoHocTapActions';
import { thaiDoHocTapTieuChiActions } from './nhan-xet/thaiDoHocTapTieuChiActions';

import { DiemVanMinhTieuChiActions } from './diemvanminh-tieuchi/diemVanMinhTieuChiActions';
import { diemVanMinhKetQuaActions } from './diemvanminh-ketqua/diemVanMinhKetQuaActions';
import { tongHopDinhKyActions } from './tong-hop/tongHopDinhKyActions';
import { baiVietCommentActions } from './bai-viet-comment/baiVietCommentActions';
import { dotDangKyActions } from './dangky-monhoc/dotDangKyActions';
import { dotDangKyNhomMonHocActions } from './dangky-monhoc/dotDangKyNhomMonHocActions';
import { dotDangKyMonHocActions } from './dangky-monhoc/dotDangKyMonHocActions';
import { dotDangKyKetQuaActions } from './dangky-monhoc/dotDangKyKetQuaActions';
import { chamTietActions } from './cham-tiet/chamTietActions';
import { chamTietLoaiTietActions } from './cham-tiet/chamTietLoaiTietActions';
import { formKhaoSatActionsWrapper } from './form-khaosat/formKhaoSatActionsWrapper';
import { appNhomBaiVietActions } from './bai-viet/nhomBaiVietActions';
import { hocSinhHoTroActions } from './hoc-sinh/hocSinhHoTroActions';
import { mealActions } from './danh-muc/mealAction';

export const actions = {
    account: accountActions,
    processAccount: processAccountActions,
    appPhuHuynhWrapper: appPhuHuynhActionsWrapper,
    auth: authActions,
    categorySource: categorySourceActions,
    common: commonActions,
    dictionary: dictionaryActions,
    monHoc: monHocActions,
    lopBoMonWrapper: lopBoMonActionWrapper,
    hocSinh: hocSinhActionsWrapper,
    hocSinhHoTro: hocSinhHoTroActions,
    soDiem: soDiemActionsWrapper,
    toKhoi: toKhoiActions,
    giaoVien: giaoVienActions,
    dgtxDmHocTap: dgtxDmHocTapActions,
    dgtxDmPhamChat: dgtxDmPhamChatActions,
    soNhanXetItem: soNhanXetItemActions,
    dgdkDmNlpcSetAction: dgdkDmNlpcSetActions,
    giaoVienNhanXet: giaoVienNhanXetActions,
    loaiPhong: loaiPhongActions,
    phongHoc: phongHocActions,
    thoiKhoaBieuWrapper: thoiKhoaBieuActionsWrapper,
    danhMucWrapper: danhMucActionsWrapper,
    uploadFile: uploadActions,
    baiViet: baiVietActions,
    baiVietTrangThai: baiVietTrangThaiActions,
    baiVietDetail: baiVietDetailActions,
    baiVietNhom: appNhomBaiVietActions,
    notify: notifyActions,
    diemDanh: diemDanhActions,
    diemDanhAn: diemDanhAnActions,
    diemDanhStatus: diemDanhStatusActions,
    nhanXetHangNgay: nhanXetHangNgayActions,
    baiTapWrapper: {
        type: baiTapTypeActions,
        baiTap: baiTapActions,
        baiTapKetQua: baiTapKetQuaActions,
        baiTapKetQuaLop: baiTapKetQuaLopActions
    },
    nhapDiem: nhapDiemActions,
    lopChuNhiem: lopChuNhiemActions,
    thaiDoHocTapWrapper: {
        thaiDoHocTap: thaiDoHocTapActions,
        thaiDoHocTapTieuChi: thaiDoHocTapTieuChiActions
    },
    diemVanMinhKetQua: diemVanMinhKetQuaActions,
    diemVanMinhTieuChi: DiemVanMinhTieuChiActions,
    danhGia: {
        dinhKy: {
            nlpc: dgdkNlpcActions,
            hocTap: dgdkHocTapActions
        },
        thuongXuyen: {
            nlpc: dgtxKetQuaActions,
            hocTap: dgtxKetQuaMonHocActions
        }
    },
    lopHanhChinhSoDiem: lopHanhChinhSoDiemActions,
    baoCaoTienBo: baoCaoTienBoActions,
    xepHangHocSinh: xepHangHocSinhActions,
    lopMonHoc: lopMonHocActions,
    tongHop: {
        dinhKy: tongHopDinhKyActions
    },
    baiVietComment: baiVietCommentActions,
    dangKyMonHoc: {
        dotDangKy: dotDangKyActions,
        dotDangKyNhomMonHoc: dotDangKyNhomMonHocActions,
        dotDangKyMonHoc: dotDangKyMonHocActions,
        dotDangKyKetQua: dotDangKyKetQuaActions
    },
    khenThuongKyLuat: khenThuongKyLuatActions,
    chamTiet: chamTietActions,
    chamTietLoaiTiet: chamTietLoaiTietActions,
    formKhaoSatWrapper: formKhaoSatActionsWrapper,
    meal: mealActions
}
